.about-slider {
    display: grid;
    grid-row-gap: 20px;
    justify-content: stretch;

    &_consultants {
        margin-right: -20px;

        @media (min-width: $smallest-size) {
            margin-right: -32px;
        }

        @media (min-width: $small-mid-size) {
            margin-right: 0;
        }

        .about-slider__arrows,
        .about-slider__thumbs {
            display: none;

            @media (min-width: $small-mid-size) {
                display: block;
            }
        }

        .about-slider__main-item {
            opacity: .6;
            transition: opacity 300ms ease;

            &.swiper-slide-active {
                opacity: 1;
            }
        }
    }
}
.about-slider__main {
    width: 100%;
}
.about-slider__thumbs {
    width: 100%;
}

.about-slider__wr-main {
    display: grid;

    width: 100%;

    position: relative;
}
.about-slider__arrows {
    width: 100%;

    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
}
.about-slider__btn {
    width: 32px;
    height: 32px;
    border: 1px solid $color-laguna;

    position: absolute;

    background-color: $color-laguna;
    border-radius: 50%;
    transition: 300ms ease-in-out;
    cursor: pointer;

    @media (min-width: $small-mid-size) {
        width: 36px;
        height: 36px;
    }

    &_prev {
        left: 0;
        transform: translate(-50%, -50%) rotate(-90deg);
    }
    &_next {
        right: 0;
        transform: translate(50%, -50%) rotate(90deg);
    }

    &:hover {
        @media (hover: hover) {
            background-color: #ffffff;
        }

        &::before {
            @media (hover: hover) {
                border-top-color: $color-laguna;
                border-right-color: $color-laguna;
            }
        }
    }

    &::before {
        content: '';

        width: 9px;
        height: 9px;
        border-top: 2px solid #ffffff;
        border-right: 2px solid #ffffff;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -30%) rotate(-45deg);
    
        transition: 300ms ease-in-out;
    }
}

.about-slider__thumbs-item {
    &:not(.swiper-slide-thumb-active) {
        .about-slider__wr-img {
            opacity: .8;
        }
    }
}
.about-slider__wr-img {
    transition: 300ms ease-in-out;

    @include img-wrap;
    @include aspect-ratio-w-pseudo(3, 2);
}