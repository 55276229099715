@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

@import '../about-gal/about-slider.scss';

.about-consultants {
    overflow: hidden;
}
.about-consultants__container {
    display: grid;
    grid-row-gap: 40px;

    padding-bottom: 0;

    @media (min-width: $medium-size) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 32px;
    }
    @media (min-width: $largest-size) {
        grid-column-gap: 48px;
    }
}
.about-consultants__col-left {
    max-width: 580px;
}
.about-consultants__col-right {
    @media (min-width: $small-size) {
        justify-self: end;

        width: 100%;
        max-width: 640px;
    }
}

.about-consultants__heading {
    margin-bottom: 20px;
}
.about-consultants__arrow-link {
    margin-top: 30px;

    @media (min-width: $small-size) {
        margin-top: 40px;
    }
}
.about-consultants__badge {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 30px;

    text-align: center;

    @media (min-width: $small-mid-size) {
        flex-direction: row;

        margin-top: 40px;

        text-align: left;
    }
}
.about-consultants__badge-logo {
    @include img-wrap;

    flex-shrink: 0;

    width: 100px;
    height: 100px;
    margin-bottom: 20px;

    @media (min-width: $small-mid-size) {
        margin-bottom: 0;
        margin-right: 20px;
    }
}
.about-consultants__badge-text {
    max-width: 350px;

    font-size: 14px;
    line-height: 160%;

    @media (min-width: $small-mid-size) {
        max-width: 440px;
    }
}